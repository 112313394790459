<div
    class="show d-block banned-modal w-full"
    id="myModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    aria-hidden="true"
    style="position: fixed; top: 33%; left: 50%; transform: translate(-50%, -50%); z-index: 9999;"
>
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body text-center">
                <span class="icon-medium m-1 mb-1 flex justify-center">
                    <!-- @include('elements.icon', ['icon' => 'ban-outline']) -->
                </span>
                <div>
                    You did not retweet too many times in rounds, hence you cannot attend new ones. Contact Support to
                    lift that ban.
                </div>
            </div>
            <div class="flex justify-around pb-3">
                <span class="">See your <a href="{'/rounds/history'}">History</a></span>
                <a href="{'/contact'}" class="flex items-center justify-end">
                    <span class="mr-2 flex items-center">
                        <!-- @include('elements.icon', [
                            'icon' => 'help',
                            'variant' => 'small',
                            'classes' => '',
                        ]) -->

                        Support
                    </span>
                </a>
            </div>
        </div>
    </div>
</div>
